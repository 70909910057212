.content--container {
  width: 300px;
  height: 300px;
  overflow: hidden;
  background: #0087be;
  /* cursor: pointer; */
}

.content--container__thing_blue {
  width: 25px;
  height: 25px;
  background-color: blue;
  border-radius: 50%;
  /* border: 15px rgb(255, 199, 0) solid; */
}

.content--container__thing_red {
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 50%;
  /* border: 15px rgb(255, 199, 0) solid; */
}

.red--corner {
  position: relative;
  top: 92px;
  left: 249px;
  background-color: red;
  width: 25px;
  height: 25px;
  z-index: 100;
}

.blue--corner {
  position: relative;
  top: 340px;
  left: 26px;
  background-color: blue;
  width: 25px;
  height: 25px;
  z-index: 100;
}
.centerRingLogo {
  position: relative;
  bottom: -170px;
  right: -78.5px;
  width: 150px;

  z-index: 150;
}

.innerRing {
  width: 250px;
  height: 250px;
  background: #0087be;
  border: 1px solid white;
  position: relative;
  top: 25px;
  left: 25px;
}

.centerRingLogoData {
  position: absolute;
  left: 115px;
  top: 213.14165px;
  width: 270px;
  /* for octagon */
  /* transform: rotate(315deg);  */
  z-index: 150;
}
.centerRingLogoRing {
  position: absolute;
  left: 75px;
  top: 129.525px;
  width: 150px;
  /* for octagon */
  /* transform: rotate(315deg);  */
  z-index: 150;
}
.innerRingData {
  width: 450px;
  height: 450px;
  background: #0087be;
  border: 1px solid white;
  position: absolute;
  top: 25px;
  left: 25px;
}
.innerRingRing {
  width: 250px;
  height: 250px;
  background: #0087be;
  border: 1px solid white;
  position: absolute;
  top: 25px;
  left: 25px;
}

.content--container__Data {
  width: 500px;
  height: 500px;
  overflow: hidden;
  background: #0087be;
  position: relative;
  /* cursor: pointer; */
}
.content--container__Ring {
  width: 300px;
  height: 300px;
  overflow: hidden;
  background: #0087be;
  position: relative;
  /* cursor: pointer; */
}
.red--corner__Data {
  position: absolute;
  top: 26px;
  left: 449px;
  background-color: red;
  width: 25px;
  height: 25px;
  z-index: 100;
}
.red--corner__Ring {
  position: absolute;
  top: 26px;
  left: 249px;
  background-color: red;
  width: 25px;
  height: 25px;
  z-index: 100;
}

.blue--corner__Data {
  position: absolute;
  top: 449px;
  left: 26px;
  background-color: blue;
  width: 25px;
  height: 25px;
  z-index: 100;
}
.blue--corner__Ring {
  position: absolute;
  top: 249px;
  left: 26px;
  background-color: blue;
  width: 25px;
  height: 25px;
  z-index: 100;
}

/* Ring Blob */
.green_data {
  position: absolute;
  left: 25px;
  top: 25px;
  background: rgba(51, 217, 178, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  height: 90px;
  width: 90px;
  transform: scale(1);
  animation: pulse-green 2s infinite;
  z-index: 200;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.red_data {
  position: absolute;
  left: 115px;
  top: 25px;
  background: rgba(217, 84, 51, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(217, 84, 51, 1);
  height: 90px;
  width: 90px;
  transform: scale(1);
  animation: pulse-red 2s infinite;
  z-index: 200;
}
.red_body_blob {
  position: absolute;
  top: 10px;
  left: 100px;
  background: rgba(217, 84, 51, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(217, 84, 51, 1);
  height: 25px;
  width: 25px;
  transform: scale(1);
  animation: pulse-red 2s infinite;
  z-index: 200;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 84, 51, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(217, 84, 51, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 84, 51, 0);
  }
}

.blue_data {
  position: absolute;
  left: 205px;
  top: 25px;
  background: rgba(54, 51, 217, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(54, 51, 217, 1);
  height: 90px;
  width: 90px;
  transform: scale(1);
  animation: pulse-blue 2s infinite;
  z-index: 200;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(54, 51, 217, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(54, 51, 217, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(54, 51, 217, 0);
  }
}

.yellow_data {
  position: absolute;
  left: 295px;
  top: 25px;
  background: rgba(217, 206, 51, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(217, 206, 51, 1);
  height: 90px;
  width: 90px;
  transform: scale(1);
  animation: pulse-yellow 2s infinite;
  z-index: 200;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 206, 51, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(217, 206, 51, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 206, 51, 0);
  }
}

.pink_data {
  position: absolute;
  left: 385px;
  top: 25px;
  background: rgba(217, 51, 115, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(217, 51, 115, 1);
  height: 90px;
  width: 90px;
  transform: scale(1);
  animation: pulse-pink 2s infinite;
  z-index: 200;
}

@keyframes pulse-pink {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 115, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(217, 51, 115, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 115, 0);
  }
}
/* Octagon style */
.octagonWrap {
  width: 500px;
  height: 500px;
  float: left;
  position: relative;
  overflow: hidden;
}

.octagon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: rotate(45deg);
  background: rgb(150, 150, 150);
  border: 3px solid #d20a0a;
}

.octagon:before {
  position: absolute;
  /* There needs to be a negative value here to cancel
   * out the width of the border. It's currently -3px,
   * but if the border were 5px, then it'd be -5px.
   */
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  transform: rotate(45deg);
  content: "";
  border: inherit;
}
.octagon-inner {
  position: relative;
}
