@import "~antd/dist/antd.dark.less"; // Introduce the official dark less style entry file
// @import "~antd/dist/antd.compact.less"; // Introduce the official compact less style entry file

/* width */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc92b;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: #ffc92b;
}

@primary-color: #f0f0f0;@body-background: #141414;